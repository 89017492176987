define('KastnerUtils', ['jquery'], function($)
{
	"use strict";

	var $window = $(window);
	var gridMinWidth = 222;
	var isTouchScreen = (('createTouch' in document) || ('ontouchstart' in window) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
	var clickEvent = isTouchScreen ? 'touchstart' : 'click';

	var waitForFinalEvent = (function () {
	  var timers = {};
	  return function (callback, ms, uniqueId) {
	    if (!uniqueId) {
	      uniqueId = "Don't call this twice without a uniqueId";
	    }
	    if (timers[uniqueId]) {
	      clearTimeout (timers[uniqueId]);
	    }
	    timers[uniqueId] = setTimeout(callback, ms);
	  };
	})();
/*
	// require jquery-bridget, it's included in packery.pkgd.js
	require(['jquery-bridget/jquery-bridget'], function(jQueryBridget)
	{
		// make Packery a jQuery plugin
		jQueryBridget('packery', Packery, $);
	});
*/
	var equalize = function()
	{
		// go through each equalizing group
		$('.group.equalize, .products-grid').each( function()
		{
			// get the columns
			var $columns = $(this).children();

			// reset the min height
			$columns.css('min-height','');

			// get the max height
			var maxHeight = Math.max.apply(null, $columns.map(function () {
			    return $(this).css('float') == 'none' ? 0 : $(this).outerHeight();
			}).get());

			// set the min height
			$columns.css('min-height',(Math.ceil(maxHeight)+1)+'px');
		});
	};


	/**
	 * Sets min-height of #container
	 */
	var updateContainer = function()
	{
		// set min-height of #container
		var $footer = $('#footer');
		var $container = $('#container');
		if( $footer.length )
		{
			if( $window.height() > $footer.offset().top + $footer.outerHeight() )
			{
				var height = $window.height() - ( $('#header').length ? $('#header').outerHeight() : 0 ) - $footer.outerHeight();
				$container.css('min-height', height+'px');
			}
		}
		else
		{
			if( $window.height() > $container.offset().top + $container.height() )
			{
				var height = $window.height() - $container.offset().top;
				$container.css('min-height',height+'px');
			}
		}
	};

/**
 * Not needed anymore with flex
 * 
	var updateGridSpan = function()
	{
		$('.products-grid').each( function()
		{
			var $grid = $(this);
			var availableWidth = $grid.width();
			var count = Math.floor( availableWidth / gridMinWidth );
			if( count >= 12 )
			{
				count = 12;
			}
			else
			{
				count = Math.max(1, Math.min(6, Math.floor( availableWidth / gridMinWidth )));
				if( count == 5 ) count = 4;
			}
			var span = Math.ceil( 12 / count );
			var $items = $grid.children();
			$items.removeClass('span1 span2 span3 span4 span5 span6 span7 span8 span9 span10 span11 span12');
			$items.addClass('span'+span);
		});
	};
 */

	var removeStd = function()
	{
		$('#main .std').each( function()
		{
			var $this = $(this);
			if( $this.text().trim().length < 3 )
				$this.remove();
		});
	};

	var getMasterFilter = function()
	{
		var masterFilter ='';
		$('.header__search-form nav.master-filter li.active').each( function() { masterFilter = $(this).data('parameter'); });
		return masterFilter;
	};

	var openCatNav = function( $item )
	{
		/*
		// open category nav automatically
		var $catnav = $('.category-nav');
		var $catul = $catnav.children('ul');
		if( $item.data('category') && $catnav.length )
		{
			var $categories = $catnav.find('li');
			$categories.filter('[data-id="'+$item.data('category')+'"]').each( function()
			{
				var $category = $(this);
				$catnav.addClass('has-active');
				$categories.removeClass('active');
				$category.addClass('active');
				$category.parents().filter('li.parent').addClass('active');

				// reset scroll
				var scrollTop = $catul.scrollTop();

				// global offset
				var categoryOffset = $category.offset().top;

				// container offset
				var containerOffset = $catul.offset().top;

				// middle
				var scrollMiddle = $catul.height() * 0.5 - $category.height() * 0.5;

				// final value
				var finalScroll = Math.max(0, categoryOffset - containerOffset + scrollTop - scrollMiddle);

				// scroll to
				$catul.animate({ scrollTop: finalScroll}, 300);
			});
		}
		*/
	};

/*
	var updatePackery = function()
	{
		$('[data-packery]').each(function()
		{
			$(this).packery();
		});
	};
*/

	var updateSwipers = function()
	{
		$('.swiper-container').each(function()
		{
			$(this)[0].swiper.update();
		});
	}

	return {
		waitForFinalEvent: waitForFinalEvent,
		equalize: equalize,
		updateContainer: updateContainer,
		removeStd: removeStd,
		getMasterFilter: getMasterFilter,
		openCatNav: openCatNav,
		updateSwipers: updateSwipers,
		clickEvent: clickEvent
	}
});
