require(['jquery'], function($){
	'use strict';
	var isTouchScreen = (('createTouch' in document) || ('ontouchstart' in window) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
	var clickEvent = isTouchScreen ? 'touchstart' : 'click';
	var $body = $('body');
	$('#mobilemainnavbutton').on(clickEvent, function(){
		$body.toggleClass('show-offcanvas');
	});

	$('#offcanvas').on(clickEvent, function(e) {
		if ($(e.target).is('#offcanvas')) {
			$body.removeClass('show-offcanvas');
		}
	});
});
