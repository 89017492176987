define('KastnerCart', ['KastnerUtils', 'jquery', 'tingle'], function(KastnerUtils, $, tingle)
{
	"use strict";

	var initDone = false;

	var requestQueue = [];
	var requestsRunning = false;

	// colors of the steps of shipping costs; for 3 and 4 steps
	var colorsShipping = ['#5bd721', '#ffbf00', '#ff8000', 'red'];
	var colorsShipping3 = ['#5bd721', '#ffbf00', 'red'];

	var isTouchScreen = (('createTouch' in document) || ('ontouchstart' in window) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
	var clickEvent = isTouchScreen ? 'touchstart' : 'click';
	
	var PopUp = (function()
	{
		var $bar = null;
		var timeout = null;
		var $popup = null;

		var init = function()
		{
			$bar = $('#cart-progress .bar');
			if (null === $popup)
			{
				var $button = $('<button class="button check blue">Ok</button>');
				$button.click(hidePopup);
				$popup = $('<div id="cart-popup"><div class="inside"><div class="content"><div class="text"></div></div></div></div>');
				$popup.find('.content').append($button);
				$('body').append( $popup );
			}
		};

		var showBar = function(duration)
		{
			// default duration
			duration = typeof duration !== 'undefined' ? duration : 2500;

			// clear previous timeout if present
			if (null !== timeout !== null)
			{
				clearTimeout(timeout);
			}

			// show the progress bar
			$bar.fadeIn(200);

			// only hide if not excplicitly disabled
			if (duration !== false)
			{
				// set new timeout
				timeout = setTimeout(function()
				{
					$bar.fadeOut(300, function()
					{
						$bar.width(0);
						$bar.removeClass('finished error');
					});
				}, duration);
			}
		};

		var showPopup = function()
		{
			$popup.fadeIn(200);
		};

		var hidePopup = function()
		{
			$popup.fadeOut(200);
		};

		var setProgress = function(progress, duration)
		{
			$bar.css('width',progress + '%');
			if( progress >= 99.5 )
			{
				$bar.addClass('finished');
				showBar(duration);
			}
			else
			{
				$bar.removeClass('finished');
				showBar(false);
			}
		};

		var setStatus = function(status)
		{
			var modal = new tingle.modal({
				footer: true,
				closeMethods: ['escape'],
				cssClass: ['cart', 'cart--status'],
				closeLabel: "Close"
			});

			modal.addFooterBtn('Ok', 'button blue check tingle-btn--pull-right', function(){
				modal.close();
			});
		
			modal.setContent(status);

			modal.open();
		};

		var setError = function(error)
		{
			var modal = new tingle.modal({
				footer: true,
				closeMethods: ['button', 'escape'],
				cssClass: ['cart', 'cart--error'],
				closeLabel: "Close"
			});

			modal.addFooterBtn('Ok', 'button blue check tingle-btn--pull-right', function(){
				modal.close();
			});
		
			modal.setContent(error);

			modal.open();
		};

		return {
			init: init,
			setProgress: setProgress,
			setStatus: setStatus,
			setError: setError
		}
	})();

	var removeCartItem = function($item)
	{
		// fadeout the item
		$item.fadeOut(function()
		{ 
			// check if this is the last item
			if ($item.siblings().length == 0)
			{
				// reload the cart
				if (requestsRunning)
				{
					$(document).on('queue-complete', function()
					{
						window.location = window.location;
					});
				}
				else
				{
					window.location = window.location;
				}
			}

			// remove the item
			$item.remove();
		});
	};

	var ajaxResponse = function( response, origin )
	{
		if( typeof origin === 'undefined' ) origin = null;

		if (typeof response !== 'undefined' && response !== null)
		{
			if (typeof response.items !== 'undefined')
			{
				var $count = $('#header .cart-button .cart-count');
				$count.text( response.items );
				if( response.items > 0 )
				{
					$count.fadeIn();
				}
				else
				{
					$count.fadeOut();
				}			
			}

			if (typeof response.total !== 'undefined')
			{
				$('#header .shipping-costs').attr('data-current', response.total);
			}

			if (typeof response.total_html !== 'undefined')
			{
				$('#header .total-number').text( response.total_html );
				$('#header .total-container').removeClass('hide');
				$('.cart-table-footer div.sum').text( response.total_html );
			}

			if (typeof response.cart !== 'undefined') {
				$.each(response.cart, function(i, item) {
					if (typeof item.product === 'undefined') {
						return true;
					}

					$('.cart-table .item[data-product-id="'+item.product+'"]').each(function() {
						var $product = $(this);

						if (typeof item.position !== 'undefined') {
							$product.find('li.position').text(item.position);
						}

						if (typeof item.sum !== 'undefined') {
							$product.find('.subtotal span.price').text(item.sum);
						}

						if (typeof item.price !== 'undefined') {
							$product.find('li.price .price-wrapper').each((i, wrapper) => {
								const priceSpan = wrapper.querySelector('span.price');

								if (null !== priceSpan) {
									priceSpan.innerText = item.price;
								} else {
									wrapper.innerText = item.price;
								}
								
								if (wrapper.classList.contains('clickable-allowed')) {
									wrapper.classList.add('clickable');
								}
							});
						}

						// Only update input if origin is not from cart
						if (origin !== 'cart' && typeof item.qty !== 'undefined' && typeof item.multi !== 'undefined' && typeof item.unit !== 'undefined') {
							$product.find('input[name="qty"]').val(item.qty);
							$product.find('input[name="multi"]').val(item.multi);
							$product.find('.unitswitch[data-current]').attr('data-current', item.unit);
							$product.find('input[name="unit"][value="'+item.unit+'"]').prop('checked', true);
						}

						$product.addClass('in-cart');
					});
				});

				$('.cart-table .item').not('.in-cart').fadeOut();
			}

			if (response.status == 'ERROR')
			{
				PopUp.setError(response.message);
			}

			if (typeof response.cart_html !== 'undefined' && response.cart_html)
			{
				$('.cart-table').each(function()
				{
					var $oldCart = $(this).find('.cart-table-items');
					var $newCart = $(response.cart_html);
					var contentUpdated = false;

					$newCart.find('.item').each(function() {
						var $item = $(this);

						if ($oldCart.find('.item[data-product-id="' + $item.attr('data-product-id') + '"]').length === 0) {
							$oldCart.last().prepend($item);
							contentUpdated = true;
						}
					});

					if (contentUpdated) {
						$oldCart.trigger('contentUpdated');
					}
				});
			}

			var event = $.Event('queue-response');
			event.response = response;
			$(document).trigger(event);
		}
	};

	var disallowNavigation = function()
	{
		window.onbeforeunload = function() {
		    return "Wenn sie diese Seite verlassen, gehen alle nicht gespeicherten Änderungen verloren.";
		}
	};

	var allowNavigation = function()
	{
		window.onbeforeunload = null;
	};

	var totalRequests = 0;

	var ajaxRequest = function (request) {
		// set to running
		requestsRunning = true;

		// determine percentage
		var percentage = parseFloat( ( ( totalRequests - ( requestQueue.length + 1 ) ) / totalRequests ) * 100 ) .toFixed(2);

		// set percentage
		PopUp.setProgress( percentage );

		// execute request
		$.ajax({
			url: request.url,
			dataType: 'json',
			type: request.type,
			data: request.data,
			global: false,
			success: function( response )
			{
				// general callback
				ajaxResponse( response, request.origin );

				// request callback
				if( request.success )
				{
					request.success( response );
				}
			},
			complete: function()
			{
				// check if there are still elements in the queue
				if( requestQueue.length > 0 )
				{
					// request next item
					ajaxRequest( requestQueue.shift() );
				}
				else
				{
					// set percentage
					PopUp.setProgress( 100, 260 );

					// set to not running
					requestsRunning = false;
					totalRequests = 0;

					// trigger complete
					$(document).trigger('queue-complete');
				}
			}
		});	

		// if request queue is on 0, allow navigation
		if( requestQueue.length == 0 )
			allowNavigation();
		// otherwise disallow
		else
			disallowNavigation();
	};

	/**
	 * @param {string} url
	 * @param {object} data - The parameters
	 * @param {string} type - GET (default) or POST
	 * @param {function} success - Success callback
	 * @param {string} origin - Custom origin identifier
	 */
	var queueRequest = function(url, data, type, success, origin)
	{
		// validate variables
		if( typeof url     === 'undefined' ) return;
		if( typeof data    === 'undefined' ) data = {};
		if( typeof type    === 'undefined' ) type = 'GET';
		if( typeof success === 'undefined' ) success = false;
		if( typeof origin  === 'undefined' ) origin = null;

		// push request to queue
		requestQueue.push({
			url: url,
			data: data,
			type: type,
			success: success,
			origin: origin
		});

		// increase total requests
		totalRequests++;

		// check if requests are already running
		if( !requestsRunning )
		{
			// initiate request queue
			$(document).trigger('queue-start');
			ajaxRequest( requestQueue.shift() );
		}
	};

	var init = function()
	{
		// get containers
		var $document = $(document);

		// don't do anything anymore after this point, if already initialized once
		if( initDone )
			return;

		PopUp.init();

		$document.click( function(e)
		{
			var $target = $(e.target);
			if( $target.closest('.confirm-remove').length == 0 && $target.closest('li.delete').length == 0 )
				$('.cart-table .confirm-remove').removeClass('show');
			if( $target.closest('.btn-empty-cart').length == 0 )
				$('.btn-empty-cart').addClass('hide');
		});

		// catch submit on check prices form
		$('#check-prices-form button[type="submit"]').click(function(e)
		{
			e.preventDefault();
			var $cart = $('#cart-form');
			var $check = $(this).closest('form');
			var execute = function() { $check.submit(); }
			if( requestQueue.length > 0 )
			{
				$(document).on('queue-complete', execute);
			}
			else
			{
				execute();
			}
			return false;
		});

		// init is done
		initDone = true;
	}

	return {
		init: init,
		queueRequest: queueRequest,
		queueRunning: function() { return requestsRunning; },
		removeCartItem: removeCartItem,
		disallowNavigation: disallowNavigation,
		setPopupStatus: function(status)
		{
			PopUp.setStatus(status);
		},
		setPopupError: function(error)
		{
			PopUp.setError(error);
		},
		setPopupProgress: function(progress)
		{
			PopUp.setProgress(progress);
		}
	}
});
