require(['jquery'], function($) {
	'use strict';

	var $window = $(window);
	var t1 = $window.height() * 0.333;
	var t2 = $window.height() * 0.166;

	$(document).ready(function() {
		$('#back_to_top').each(function() {
			var $button = $(this);
			var $body = $('body');
	
			var checkScroll = function() {
				var top = $window.scrollTop();
				if (top > t1) {
					$button.addClass('show');
					$body.addClass('show-btt');
				}
				else {
					if ($button.hasClass('show') && top < t2) {
						$button.removeClass('show');
						$body.removeClass('show-btt');
					}
				}
				setTimeout(function() {
					window.requestAnimationFrame(checkScroll);
				}, 100);
			};
			window.requestAnimationFrame(checkScroll);
	
			$button.on('click', function(e) {
				e.preventDefault();
				$('html, body').stop().animate({scrollTop: 0}, 400, 'swing');
				return false;
			});
		});
	});
});
