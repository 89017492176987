require(['jquery', 'factfinder'], function($, factfinder) {
	'use strict';

	var $window = $(window);
	var $body = $('body');
	var $search = $('#search');

	$('.search-button-mobile').click(function(e){
		e.preventDefault();
		if (!$body.hasClass('show-search') && $window.width() <= 1040) {
			$body.addClass('show-search');
			$search.focus();
		}
		return false;
	});

	$('.ff-filter-button').click(function(e){
		if (!$body.hasClass('show-filter') && $window.width() <= 1040) {
			$body.addClass('show-filter');
		}
	});

	$('.search-button-mobile-close').click(function(e){
		$body.removeClass('show-search');
		$search.val('');
		factfinder.communication.ResultDispatcher.dispatch({}, undefined, ['suggest:hide']);
		e.preventDefault();
		return false;
	});

	$('#search').on('keydown', function(e) {
		if(e.keyCode == 13) {
			$('html, body').animate({ scrollTop: 0 }, 400);
		}
	});

	$('.search-button').on('click', function() {
		$('html, body').animate({ scrollTop: 0 }, 400);
		factfinder.communication.ResultDispatcher.dispatch({}, undefined, ['suggest:hide']);
		return true;
	});

    $(document).on('click', function(e) {
		const $target = $(e.target);

        if (0 === $target.closest('.search-box').length || $target.closest('.master-filter').length > 0) {
			factfinder.communication.ResultDispatcher.dispatch({}, undefined, ['suggest:hide']);
        }

		if (0 === $target.closest('.search-box').length && !$search.val()) {
			$body.removeClass('show-search');
		}

		if (0 === $target.closest('.ff-filter-button').length && 0 === $target.closest('#left').length) {
			$body.removeClass('show-filter');
		}
    });
});
