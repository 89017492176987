require(['KastnerUtils', 'KastnerMain', 'KastnerCart', 'jquery'], function(KastnerUtils, KastnerMain, KastnerCart, $)
{
	"use strict";

	var $window = $(window);
	var $document = $(document);
	var isTouchScreen = (('createTouch' in document) || ('ontouchstart' in window) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));

	$document.ready( function()
	{
		// init cart
		KastnerCart.init();

		// update grid span
		//KastnerUtils.updateGridSpan();

		// equalize the columns
		KastnerUtils.equalize();

		// update #left and #right content
		//KastnerUtils.updateLeftRight();

		// init main
		KastnerMain.init();

		// update container
		KastnerUtils.updateContainer();

		// remove empty std containers
		KastnerUtils.removeStd();
	});

	$window.on('load', function()
	{
		// update grid span
		//KastnerUtils.updateGridSpan();

		// equalize columns
		KastnerUtils.equalize();

		// give search bar focus
		if (window.innerWidth > 1040 && !isTouchScreen) {
			$('#search').focus();
		}

		// update #left and #right content
		//KastnerUtils.updateLeftRight();

		// update container
		KastnerUtils.updateContainer();

		// point to category
		/*$('.category-products [data-category]').each( function()
		{
			KastnerUtils.openCatNav( $(this) );
			return false;
		});*/
	});

	$window.on('resize', function()
	{
		KastnerUtils.waitForFinalEvent( function()
		{
			// update grid span
			//KastnerUtils.updateGridSpan();

			// equalize columns
			KastnerUtils.equalize();

			// update #left and #right content
			//KastnerUtils.updateLeftRight();

		}, 500, 'scripts-window-resize');
	});

	// contentUpdated fallback
	document.addEventListener('triggerContentUpdated', (e) => {
		$(e.detail).trigger('contentUpdated');
	});

	// make KastnerCart available globally for now for the stimulus controllers
	window.KastnerCart = KastnerCart;
});
