define('KastnerMain', ['KastnerUtils', 'jquery', 'jscookie', 'tabs'], function(KastnerUtils, $, Cookies)
{
	"use strict";

	var initDone = false;
	var scrollController = null;

	var init = function()
	{
		if( initDone )
			return;

		// get containers
		var $window = $(window);
		var $document = $(document);
		var $body = $('body');
		var $main = $('#main');
		var $topCategoryNav = $('#topCategoryNav');
		var isTouchScreen = (('createTouch' in document) || ('ontouchstart' in window) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
		var clickEvent = isTouchScreen ? 'touchstart' : 'click';

		// product tabs
		$('.product-collateral').tabs();

		// top nav categories
		$('#topnav').each(function() {
			var $firstItem = $(this).find('.nav.item').first();

			$firstItem.on(clickEvent, function(e) {
				e.preventDefault();
				$topCategoryNav.addClass('show');
				return false;
			});

			$firstItem.first().mouseover(function() {
				$topCategoryNav.each(function() {
					$(this).addClass('show').mouseleave(function() {
						$(this).removeClass('show');
					});
				});
			});
		});

		$topCategoryNav.find('button').on(clickEvent, function() {
			$topCategoryNav.removeClass('show');
		});

		// hide column click
		$('.hide-column').off(clickEvent).on(clickEvent, function( event )
		{
			event.preventDefault();
			var $this = $(this);
			var column = $this.data('column')
			var $column = $('#'+column);

			if ($column.length == 0)
			{
				return false;
			}

			if ($body.hasClass('no-'+column))
			{
				Cookies.remove('no-'+column);
				$body.removeClass('no-'+column);
				$body.addClass('has-'+column);
			}
			else
			{
				Cookies.set('no-'+column, '1');
				$body.addClass('no-'+column);
				$body.removeClass('has-'+column);
			}

			setTimeout( function()
			{
				// update grid span
				//KastnerUtils.updateGridSpan();

				// equalize columns
				KastnerUtils.equalize();

				// update packery
				//KastnerUtils.updatePackery();

				// update swipers
				KastnerUtils.updateSwipers();
			}, 500);

		});

		var wishlistPopupMargin = function() { $('#wishlist-popup').css('margin-top', ($window.height() * 0.1)  + 'px'); }
		wishlistPopupMargin();
		$window.resize( wishlistPopupMargin );

		// data-show
		$('[data-show]').on(clickEvent, function(e) {
			var $this = $(this);
			var show = $this.data('show');
			var $target = $('#'+show);
			$('#'+show).toggleClass($target.hasClass('hide-show') ? 'show' : 'hide');
		});

		// Customer Menu click
		$('.customer-menu').on(clickEvent, function(e) {
			if (!$.contains(this, e.target)) {
				$(this).toggleClass('show');
			}
		});

		// Print PDF list
		$('.print-pdf__container').each(function() {
			var $container = $(this);

			$container.find('.print-pdf__button').on(clickEvent, function() {
				$container.toggleClass('show');
			});

			$container.find('.print-pdf__method-link').on(clickEvent, function() {
				$container.removeClass('show');
			});

			$(document).on(clickEvent, function(e) {
				if (!$.contains($container.get(0), e.target)) {
					$container.removeClass('show');
				}
			});
		});

		// init is done
		initDone = true;
	}

	var updateWishlistCount = function()
	{
		$.getJSON( BASE_URL + '/webshop/wishlist/', function(response)
		{
			if( response === 0 )
			{
				$('#header .wishlist-count').text('0').fadeOut();
			}
			else
			{
				if( response )
				{
					$('#header .wishlist-count').text( response ).fadeIn();
				}
			}
		});
	};

	return {
		init: init,
		updateWishlistCount: updateWishlistCount,
		scrollCtrl: scrollController
	}


	/*
	(function($, global)
	{
		"use strict";

		global.KastnerFactfinderTracking = function(data, url)
		{
			this.data = data;
			this.url = url;
		};

		global.KastnerFactfinderTracking.prototype =
		{
			track: function( id )
			{
				if( typeof this.data[id] !== 'undefined' )
				{
					$.post(this.url, this.data[id]);
				}
			}
		}

	})(jQuery, this);
	*/
});

define('KastnerFactfinderTracking', ['jquery'], function($)
{
	"use strict";

	var trackingUrl = null;
	var trackingData = {};

	var init = function( url )
	{
		trackingUrl = url;
	};

	var addData = function( data )
	{
		$.extend(trackingData, data);
	};

	var track = function( id )
	{
		if( typeof trackingData[id] !== 'undefined' && trackingUrl )
		{
			$.post(trackingUrl, trackingData[id]);
		}
	};

	return {
		init: init,
		addData: addData,
		track: track
	}
});