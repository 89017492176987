require(['jquery'], function($, undefined)
{
	'use strict';

	$('#left .category-nav').each(function()
	{
		var $nav = $(this);

		$nav.find('a').click(function(e)
		{
			var $a = $(this);
			if ($a.hasClass('active') || $a.hasClass('has-active') && $a.data('parent') !== undefined)
			{
				e.preventDefault();
				window.location = $a.data('parent');
				return false;
			}
		});
	});
});
