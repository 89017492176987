define('SwiperInit', ['jquery', 'swiper'], function($, Swiper) {
    'use strict';

    var init = function(container, wrapper, config) {
        // Container and wrapper
        var $container = $(container);
        var $wrapper = typeof wrapper === 'string' ? $container.find(wrapper) : $(wrapper);

        $container.addClass('swiper-container');
        $wrapper.addClass(typeof config.wrapperClass !== 'undefined' ? config.wrapperClass : 'swiper-wrapper');
        $wrapper.children().addClass(typeof config.slideClass !== 'undefined' ? config.slideClass : 'swiper-slide');

        // Navigation
        if (typeof config.navigation === 'undefined') {
            var $nextEl = $('<div class="swiper-button-next"></div>').appendTo($container);
            var $prevEl = $('<div class="swiper-button-prev"></div>').appendTo($container);

            config.navigation = {
                nextEl: $nextEl.get(),
                prevEl: $prevEl.get()
            };
        }

        // Pagination
        if (typeof config.pagination !== 'undefined') {
            if (true === config.pagination) {
                config.pagination = {
                    el: true,
                    clickable: true
                };
            }

            if (true === config.pagination.el) {
                var $pagination = $('<div class="swiper-pagination"></div>').appendTo($container);
                config.pagination.el = $pagination.get();
            }
        }

        var i = Swiper.prototype.init;
        Swiper.prototype.init = function () {
          this.touchEventsData.formElements = 'input, select, option, textarea, button, video, label';
          i.call(this);
        };

        // Init
        var swiper = new Swiper($container.get(), config);

        if ('auto' === config.slidesPerView) {
            var slidesPerGroup = Math.floor($container.width() / $wrapper.find('.swiper-slide').first().outerWidth(true));
            swiper.params.slidesPerGroup = slidesPerGroup;
            swiper.update();
        }

        return swiper;
    };

    return {
        init: init
    }
});
